import React from 'react';
import Typist from 'react-typist';
import DownloadIcon from '@mui/icons-material/Download';
import './About.css';

function About() {
    return (
        <div className="About">
            <div className="About__Title">
                <Typist>About</Typist>
            </div>
            <div className="About__Body">
                I am currently a third year in Vellore Institute of Technology,Vellore pursuing degree in Btech in the field of 
                computer Science.<br/>
                For front end I use 
                <img alt="react"src="https://img.shields.io/badge/react-%2320232a.svg?style=for-the-badge&logo=react&logoColor=%2361DAFB"/>,
                <img alt="html"src="https://img.shields.io/badge/html5-%23E34F26.svg?style=for-the-badge&logo=html5&logoColor=white"/>,
                <img alt="bootstrap"src="https://img.shields.io/badge/bootstrap-%23563D7C.svg?style=for-the-badge&logo=bootstrap&logoColor=white"/>.<br/>
                For developing other applications I like to use 
                <img alt="python"src="https://img.shields.io/badge/python-3670A0?style=for-the-badge&logo=python&logoColor=ffdd54"/>.<br/>
                For machine learning I use 
                <img alt="tensorflow"src="https://img.shields.io/badge/TensorFlow-%23FF6F00.svg?style=for-the-badge&logo=TensorFlow&logoColor=white"/>.<br/>
                For Hosting websites I use 
                <img alt="firebase"src="https://img.shields.io/badge/firebase-%23039BE5.svg?style=for-the-badge&logo=firebase"/> and 
                <img alt="cloudfare"src="https://img.shields.io/badge/Cloudflare-F38020?style=for-the-badge&logo=Cloudflare&logoColor=white"/><br/>
                For Backend I use 
                <img alt="firebase"src="https://img.shields.io/badge/firebase-%23039BE5.svg?style=for-the-badge&logo=firebase"/>, 
                <img alt="php"src="https://img.shields.io/badge/php-%23777BB4.svg?style=for-the-badge&logo=php&logoColor=white"/>, 
                <img alt="mysql"src="https://img.shields.io/badge/mysql-%2300f.svg?style=for-the-badge&logo=mysql&logoColor=white"/>,
                <img alt="django"src="https://img.shields.io/badge/django-%23092E20.svg?style=for-the-badge&logo=django&logoColor=white"/>.<br/>
                Some other tech I use are 
                <img alt="npm"src="https://img.shields.io/badge/NPM-%23000000.svg?style=for-the-badge&logo=npm&logoColor=white"/>, 
                <img alt="nodejs"src="https://img.shields.io/badge/node.js-6DA55F?style=for-the-badge&logo=node.js&logoColor=white"/>, 
                <img alt="opencv"src="https://img.shields.io/badge/opencv-%23white.svg?style=for-the-badge&logo=opencv&logoColor=white"/>.<br/>
                I also work with 
                <img alt="c++"src="https://img.shields.io/badge/c++-%2300599C.svg?style=for-the-badge&logo=c%2B%2B&logoColor=white"/>,
                <img alt="java"src="https://img.shields.io/badge/java-%23ED8B00.svg?style=for-the-badge&logo=java&logoColor=white"/>,
                <img alt="git"src="https://img.shields.io/badge/git-%23F05033.svg?style=for-the-badge&logo=git&logoColor=white"/>.<br/>
                Checkout my other projects below<br/>
                <button className="About__Body__Button">
                    <a href="./Harshit Resume" download className="Link">
                        <div className="Download__Resume">
                        Resume<DownloadIcon className="downloadBtn"/>
                        </div>
                    </a>
                </button>
            </div>
        </div>
    )
}

export default About
