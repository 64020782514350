import React from 'react';
import Typist from 'react-typist';
import './Project.css'

function Projects() {
    return (
            <div className="Projects">
                <div className="Projects__projectComp">
                    <Typist>Project </Typist>
                </div>
                <div className="Projects__projectContainer">
                    <div className="Projects__project">
                        <div className="Projects__projectImg">
                            <img src="https://lh3.googleusercontent.com/P-q52XyZ8VjhV6StlFA3vo-pSicjvkin_iJUiZOkDsZ7t4syavIQLociO6tJbTvL0hnXKW7VEbM-daDU2QoPqY1RZPcunkjgFlpjEfzpFi9-5ORB8IMXrp2M6ZobJNRZEQMpqgMm=w2400" 
                            alt="projectimg"/>
                        </div>
                        <div className="Projects__projectTitle">
                            Google Clone
                        </div>
                        <div className="Projects__projectDesc">
                            A Fully Responsive google clone. Capable of providing search results along with icons and search links 
                        </div>
                        <a target="_blank" rel="noreferrer" href="https://github.com/greywolff711/google-clone" className="Projects__projectBtn">Read</a>
                    </div>
                    <div className="Projects__project">
                        <div className="Projects__projectImg">
                            <img src="https://lh3.googleusercontent.com/gqRy0_ZtAjERfe2QFFQQAflNk9YTatEryH2WGpbtqXN4UKOMJE6QJiMaW_ymhpDs2lwLHuetonmhAX-40irs-KzKN_Bi-xjZK_ByKEyMvQKkKdvr2CLKd8FDwDltUmQAb84UwyuD=w2400" 
                            alt="projectimg"/>
                        </div>
                        <div className="Projects__projectTitle">
                            Amazon Clone
                        </div>
                        <div className="Projects__projectDesc">
                            Full Stack clone of Amazon. Capable of creating basket and provided meeans of payment using Stripe. 
                        </div>
                        <a target="_blank" rel="noreferrer" href="https://github.com/greywolff711/amazon-clone" className="Projects__projectBtn">Read</a>
                    </div>
                    <div className="Projects__project">
                        <div className="Projects__projectImg">
                            <img src="https://lh3.googleusercontent.com/u4UsAXF5cZQGtI8DvtMC3jlFatwKYlgNEQ54w_noZKpP2OiBw41Ti-FzXRLsaz3g7Ox2K_iZVJi7Ds09iFLNdIMnXHTZOOut9R7FMaJOve59nuNoXdIV20wTTR0CGHHGZwsxc2pg=w2400" 
                            alt="projectimg"/>
                        </div>
                        <div className="Projects__projectTitle">
                            Discord Bot
                        </div>
                        <div className="Projects__projectDesc">
                            Discord Bot capable of providing replies, memes, bans. <br/>Made using NodeJs. 
                        </div>
                        <a target="_blank" rel="noreferrer"href="https://github.com/greywolff711/Discord-Bot" className="Projects__projectBtn">Read</a>
                    </div>
                </div>
                
            </div>
            
    );
}

export default Projects;