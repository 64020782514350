import React from 'react';
import Typist from 'react-typist';
import './Contact.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
function Contact() {
    return(
        <div className="contact">
            <div className="contact__Title">
                <Typist>Contact </Typist>
            </div>
            <div className="contact__Text">
                &lt;Contact&gt;
            </div>
            <div className="contact__body">
                <form className="contact__Form" action="https://formspree.io/f/mnqlkbag" method="POST" onsubmit="event.preventDefault();">
                    <input name="name" type="text" placeholder="Name"></input>
                    <input name="_replyto" type="email" placeholder="Email"></input>
                    <input name="subject" type="text" placeholder="Message"></input>
                    <input type="submit" value="Send"></input>
                </form>
                <div className="contact__Info">
                    <div className="contact__Info__Name">HARSHIT JADLI</div>
                    <div className="contact__Info__Mail"><a href="mailto:harshitjadli11@gmail.com">harshitjadli11@gmail.com</a></div>
                    <div className="contact__Info__IconHolder">
                        <div className="IconHolder__Icon">
                            <a target="_blank" href="https://www.instagram.com/harshit_jadli_/" rel="noreferrer">
                                <InstagramIcon className="Icon"/>
                            </a>
                        </div>
                        <div className="IconHolder__Icon">
                            <a target="_blank" href="https://github.com/greywolff711" rel="noreferrer">
                                <GitHubIcon className="Icon"/>
                            </a>
                        </div>
                        <div className="IconHolder__Icon">
                            <a target="_blank" href="https://www.instagram.com/harshit_jadli_/" rel="noreferrer">
                                <FacebookIcon className="Icon"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact__Text">
                &lt;/Contact&gt;
            </div>
        </div>
    )
}

export default Contact;
