import React,{useState,useEffect} from 'react';
import ReactAnime from 'react-animejs'
import Loader from '../Components/Loading'
import './ChatWindow.css';
function ChaWindow() {
    const {Anime, stagger} = ReactAnime
    const[isLoading1,setIsLoading1]=useState(true);
    const[isLoading2,setIsLoading2]=useState(true);
    const[isLoading3,setIsLoading3]=useState(true);
    const[isLoading4,setIsLoading4]=useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading1(false);
        }, 1000);
        setTimeout(() => {
            setIsLoading2(false);
        }, 2000);
        setTimeout(() => {
            setIsLoading3(false);
        }, 3000);
        setTimeout(() => {
            setIsLoading4(false);
        }, 4000);
    })
    return (
        <div className="chatWindow">
            
            <div className="chatWindow__message">
                {isLoading1===true?<Loader/>:<div data-aos="zoom-in" data-aos-duration="1200" className="chat__message">Hey There 👋</div>}
            </div>
            <div className="chatWindow__message">
                {isLoading2===true?<Loader/>:<div data-aos="zoom-in" data-aos-duration="1200" className="chat__message">
                    I am Harshit Jadli. <br/>A college student learning front-end.</div>}
            </div>
            <div className="chatWindow__message">
                {isLoading3===true?<Loader/>:
                    <div data-aos="zoom-in" data-aos-duration="1200" className="chat__message">
                        <p>
                            <a target="_blank" href="https://github.com/greywolff711" rel="noreferrer">My GITHUB</a><br></br><a target="_blank" href="https://www.linkedin.com/in/harshit-jadli-7171a3206/" rel="noreferrer">My LinkedIn</a>
                        </p>
                    </div>
                }
            </div>
            <div className="chatWindow__message">
                {isLoading4===true?<Loader/>:<div data-aos="zoom-in" data-aos-duration="1200" className="chat__message">Bye</div>} 
            </div>
            <Anime
                initial={[
                    {
                    targets: ".chatWindow__message",
                    marginTop: ['2.5rem', '1rem'],
                    marginLeft: ['-20rem', 0],
                    duration: 3050,
                    delay: stagger(100,{from:'first'}),
                    easing: 'easeOutElastic',
                    }
                ]}
            >
            </Anime>
        </div>
    )
}

export default ChaWindow 
