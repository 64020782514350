import React from 'react'
import './Welcome.css'
function Welcome() {
    return (
        <div className="welcome">
            <div class="container">
                    <div class="no-face">
                        <div class="face">
                            <div class="eyes-container">
                                <div class="left eye-container">
                                    <div class="mark mark-top"></div>
                                    <div class="eye"></div>
                                    <div class="eyebag"></div>
                                    <div class="mark mark-bottom"></div>
                                </div>
                                <div class="right eye-container">
                                    <div class="mark mark-top"></div>
                                    <div class="eye"></div>
                                    <div class="eyebag"></div>
                                    <div class="mark mark-bottom"></div>
                                </div>
                            </div>
                            <div class="mouth-container">
                                <div class="mouth"></div>
                                <div class="chin"></div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Welcome
