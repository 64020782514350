import React from 'react'
import {Link} from "react-router-dom";
import './Header.css';
function Header() {
    return (
        <div className="header">
            <div className="header__container">
                <div className="header__containerLeft">
                    <Link to="/">Harshit Jadli</Link>
                </div>
                {/* <div className="header__containerRight" as="ul">
                    <div className="header__containerOptions" as="li">
                    <Link to="/about">About</Link>
                    </div>
                    <div className="header__containerOptions" as="li">
                    <Link to="/project">Project</Link>
                    </div>
                    <div className="header__containerOptions" as="li">
                        <Link to='/social'>Socials</Link>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Header