import React from 'react'
import './HeaderBody.css'
import Typist from 'react-typist';
import ChatWindow from './ChatWindow';
import Snow from './Snow';  
import WelcomeAnime from './WelcomeAnime';

function HeaderBody() {
    return (
        <div className="headerBody">
            <div className="headerBody__Snow">
                <Snow/>
            </div>
            {/* remove <Snow/> to get rid of snow effect and in css too */}
            <div className="headerBody__container">
                <div className="headerBody__container1">
                        <div className="headerBody__Chat"><ChatWindow/></div>
                        <div className="headerBody__SelfIntro">
                            <Typist className="MyTypist"><Typist.Delay ms={5000} />Hello, I am Harshit Jadli. <br/>
                            Welcome to my portfolio.<br/>
                            <br/></Typist>
                        </div>
                        <div className="headerBody__Welcome" classname="headerBody__WelcomeMsg">
                            <WelcomeAnime/>
                        </div>
                </div>    
                <div className="headerBody__container2">
                    <br/>
                    <hr></hr>
                    <br/>
                    <Typist>Made with ❤️ using React</Typist>
                </div>
            </div>
            {/* <div className="headerBody__container">
                <div class="wave one"></div>
                <div class="wave two"></div>
                <div class="wave three"></div>
                <div class="wave four"></div>
            </div>  */}
        </div>
    )
}

export default HeaderBody