import React from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function Loading() {
    return (
        <div>
            <Loader type="ThreeDots" color="#594343" height={30} width={30} />
        </div>
    )
}

export default Loading
