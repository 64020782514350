import Header from './Pages/Header';
import HeaderBody from './Pages/HeaderBody';
import Projects from './Pages/Projects';
import Contact from './Pages/Contact';
import About from './Pages/About';
import {BrowserRouter as Router,Switch,Route}from "react-router-dom";
import './App.css'
/*Temp commit*/
function App() {
  return (
  <div className="App">
    <Router>
      <Switch>
        <Route path='/about'>  
          <Header/>
        </Route>
        <Route path='/project'>  
          <Header/>
        </Route>
        <Route path='/social'>  
          <Header/>
        </Route>
        <Route path='/'>  
          <Header/>
          <HeaderBody/>
          <About/>
          <Projects/>
          <Contact/>
        </Route>
      </Switch>
    </Router>
    </div>
  );
}

export default App;
